<template>
  <CButton color="black" class="btn-badge" block @click="onClick()">
    <font-awesome-icon icon="shopping-cart" />
    <CBadge shape="pill" color="success">{{
      $store.state.shop.market_cart.items
    }}</CBadge>
  </CButton>
</template>

<script>
export default {
  name: "UiHeaderUserCart",
  methods: {
    onClick() {
      if (this.$store.state.shop.market_cart.id) {
        this.$store.commit("toggle", "cartShow");
      } else {
        const familyId = this.$store.state.connections.current.family.id;
        this.$router.push({ name: "UserMarketList", params: { id: familyId } });
      }
    },
  },
};
</script>
